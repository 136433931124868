import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// axios.defaults.baseURL = "http://192.168.29.130/splitbill/src/php/";
axios.defaults.baseURL = "https://splitup.meetarts.in/php/";
// axios.defaults.baseURL = "http://192.168.29.179:8880/vue/splitbill/src/php/";

// createApp(App).use(store).use(router).mount('#app')
const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
