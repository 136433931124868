import { createStore } from "vuex";

export default createStore({
  state: {
    userDetails:
      localStorage.getItem("userdata") == undefined
        ? null
        : JSON.parse(localStorage.getItem("userdata")),
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
